/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();
@import './custom-theme';

html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    height: 100%;
    font-family: Roboto;
}

.mat-mdc-button {
    text-transform: uppercase;
}

.mat-mdc-dialog-actions {
    display: flex !important;
    justify-content: flex-end !important;
}

.warn {
    color: mat.get-color-from-palette($app-warn) !important;
}

.accent {
    color: mat.get-color-from-palette($app-accent) !important;
}

.primary {
    color: mat.get-color-from-palette($app-primary) !important;
}

/* ============== CS Table ============= */

.cs-table {
    background-color: #424242;
    margin-bottom: 24px;
    border-radius: 4px;
}

.cs-table-row {
    border-top: dimgrey 1px solid;
    padding: 6px 24px;
    align-items: center;
}

.cs-table-row:first-child {
    border-top: none;
}

.cs-table-row.nodivider {
    border-top: none;
}

.cs-table-row.enclosing {
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
}

.cs-table-row.highlight:hover {
    background-color: #484848;
}

.cs-table-row.action:hover {
    @extend .cs-table-row, .highlight, :hover;
    cursor: pointer;
}

.cs-cell-right {
    text-align: right;
}

.disabled {
    color: slategray
}

.warning {
    color: darkorange
}

.mat-expansion-panel-body {
    padding: 0 !important;
}

.mdc-text-field {
    padding: 0 !important;
    margin-top: 10px;
}

.mat-mdc-form-field-infix {
    min-height: 0 !important;
    padding: 0 5px 0 5px;
}

.mat-mdc-card {
    padding: 10px;
}

/* ============= END CS Table =========== */

