@use '@angular/material' as mat;

@mixin navbar-theme($theme) {

  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .appContent {
    margin: 0 10px 0 10px;
    width: 80%;
    min-width: 1000px;
    max-width: 1000px;
    @media (max-width: 1000px) {
      width: 100%;
      min-width: 100px;
    }
  }

  .navbar {
    margin-bottom: 20px;
    width: 100%;
    height: 60px;
    background-color: mat.get-color-from-palette($primary, A700);
    color: mat.get-color-from-palette($foreground, base);
  }

  body {
    margin: 0;
    height: 100%;
  }

  .legend {
    width: 10px;
    height: 10px;
    display: inline-block;
  }

  #user-btn {
    overflow: hidden;
  }

  #user-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

}

div.loading {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(40, 40, 40, 0.7);
}

.mdc-button {
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
    font-size: 14px !important;
    font-weight: 500;
}
.mat-mdc-menu-item {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: unset!important;
}
